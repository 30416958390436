import React, { useState } from "react";
import styles from "./login.module.scss";
import logo from "../../assets/logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setIsLoggedIn } from "../../store/reducers/authReducers";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../services/auth.service";
import { verifyEmailCode } from "../../services/user.service";
import { Navigate, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import {
  emailValidationSchema,
  verificationValidationSchema,
} from "./login.schema";
import { ThreeDots } from "react-loader-spinner";

const Login = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [activeEmailInput, setActiveEmailInput] = useState(true);
  const [activePinInput, setActivePinInput] = useState(false);
  const [isEmail, setIsEmail] = useState("");
  // validations//
  const initialEmailValue = {
    email: "",
  };
  const initialValues = {
    email: isEmail,
    verificationCode: "",
  };
  // send pin to email//
  const handleEmail = (values) => {
    setLoading(true);
    login(values)
      .then((response) => {
        if (response && response.status === 201) {
          setActiveEmailInput(false);
          setActivePinInput(true);
          setIsEmail(values.email);
          alert.show("The pin code has been sent on email", {
            type: "success",
          });
        } else {
          setLoading(false);
          alert.show("Emial is not valid", { type: "error" });
        }
      })
      .catch((error) => {
        setLoading(false);
        alert.show("Un Authorized Email", { type: "error" });
      });
  };
  // LoggingIn//
  const handleLogin = async (values) => {
    try {
      setLoad(true);
      const response = await verifyEmailCode({
        verificationCode: values.verificationCode,
        email: isEmail,
      });
      dispatch(
        setIsLoggedIn({
          isLoggedIn: true,
          accessToken: response.data.accessToken,
        })
      );
      alert.show("Successfully Logged In", { type: "success" });
    } catch (error) {
      setLoad(false);
      alert.show("Invalid Verification code", { type: "error" });
    }
  };
  // redirect to dashboard//
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.loginForm}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          {/*send pin to email */}
          {activeEmailInput && (
            <>
              <Formik
                initialValues={initialEmailValue}
                validationSchema={emailValidationSchema}
                onSubmit={handleEmail}
              >
                <Form className={styles.formWrapper}>
                  <div className={styles.InputDiv}>
                    <label htmlFor="email">Email</label>
                    <div className={styles.input}>
                      <Field name="email" />
                    </div>
                    <div className={styles.errorWrapper}>
                      <ErrorMessage name="email">
                        {(errorText) => (
                          <div className={styles.error}>{errorText}</div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className={styles.buttonWrapper}>
                    <button type="submit" disabled={loading}>
                      {loading ? (
                        <ThreeDots
                          height="30"
                          width="30"
                          color="white"
                          ariaLabel="loading"
                          marginBottom="10px"
                        />
                      ) : (
                        "Send Pin"
                      )}
                    </button>
                  </div>
                </Form>
              </Formik>
            </>
          )}
          {/*verify and login */}
          {activePinInput && (
            <>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={verificationValidationSchema}
                onSubmit={handleLogin}
              >
                <Form className={styles.formWrapper}>
                  <div className={styles.InputDiv}>
                    <label htmlFor="email">Email</label>
                    <div className={styles.input}>
                      <Field name="email" disabled={true} />
                    </div>
                  </div>
                  <div className={styles.InputDiv}>
                    <div className={styles.input}>
                      <Field
                        type="text"
                        name="verificationCode"
                        placeholder="Enter Pin Here"
                      />
                    </div>
                    <div className={styles.errorWrapper}>
                      <ErrorMessage name="verificationCode">
                        {(errorText) => (
                          <div className={styles.error}>{errorText}</div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className={styles.buttonWrapper}>
                    <button type="submit" disabled={load}>
                      {load ? (
                        <ThreeDots
                          height="30"
                          width="30"
                          color="white"
                          ariaLabel="load"
                          marginBottom="10px"
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </Form>
              </Formik>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
