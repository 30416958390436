import React, { useState } from "react";
import { Menu } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  ToolOutlined,
  HeartOutlined,
  SettingOutlined,
  DollarOutlined,
  BellOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { setIsLoggedIn } from "../../../store/reducers/authReducers";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import NotificationModal from "../../Modals/NotificationsModal/Notifications";
import styles from "./Navigation.module.scss";

const Navigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const renderLocation = () => {
    switch (location.pathname) {
      case "/":
        return ["1"];
    }
  };
  const handleLocalStorage = (e) => {
    // window.localStorage.removeItem("persist:root");

    dispatch(
      setIsLoggedIn({
        isLoggedIn: false,
        accessToken: "",
      })
    );
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleNotificationModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        style={{ marginTop: "5vh" }}
        defaultSelectedKeys={renderLocation()}
      >
        <Menu.Item key="1" icon={<UserOutlined />}>
          <Link to="/">Mint</Link>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item
          key="7"
          icon={<LogoutOutlined />}
          onClick={handleLocalStorage}
        >
          Logout
        </Menu.Item>
        <Menu.Divider />
      </Menu>
      <NotificationModal isOpen={isOpen} toggle={toggleNotificationModal} />
    </>
  );
};

export default Navigation;
