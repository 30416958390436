import * as yup from "yup";

export const emailValidationSchema = yup.object({
  email: yup.string().email("Invalid email Formate").required("Required"),
});
export const verificationValidationSchema = yup.object({
  verificationCode: yup
    .string()
    .required("Required")
    .matches(/^\S*$/, "Whitespace is not allowed"),
});
