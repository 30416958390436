import { mintContact } from "../contracts/mintContact";

export const getTotalNFT = async () => {
  const mintContactInit = mintContact();
  const result = await mintContactInit.methods.maxSupply().call();
  return result;
};
export const getSoldNFT = async () => {
  const mintContactInit = mintContact();
  const result = await mintContactInit.methods.totalSupply().call();
  return result;
};
export const getMintFee = async () => {
  const mintContactInit = mintContact();
  const result = await mintContactInit.methods.mintFee().call();
  return result;
};
export const setMintFee = (value, account) => {
  const mintContactInit = mintContact();

  const result = mintContactInit.methods
    .setMintFee(value)
    .send({ from: account });
  return result;
};

export const setGoldMember = (value, account) => {
  const mintContactInit = mintContact();
  const result = mintContactInit.methods
    .setGoldMember(value, true)
    .send({ from: account });
  return result;
};

export const setWhiteMember = (value, account) => {
  const mintContactInit = mintContact();
  const result = mintContactInit.methods
    .setWhiteMember(value, true)
    .send({ from: account });
  return result;
};
