import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers, compose } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ReduxThunk from "redux-thunk";
import authSlice from "./reducers/authReducers";
import userSlice from "./reducers/userReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth"],
  blacklist: [],
};

const reducers = combineReducers({
  auth: authSlice,
  user: userSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false,
}).concat(ReduxThunk);

let enhancedCompose = compose;

// if (__DEV__) {
//   enhancedCompose = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;
// }

export const store = configureStore({
  reducer: persistedReducer,
  middleware: enhancedCompose(middleware),
});

export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof reducers>;
// export type AppDispatch = typeof store.dispatch;
