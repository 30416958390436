import React, { useEffect, useState } from "react";
import Login from "./components/Login/Login";
import Header from "./components/_common/Header/Header";
import Mint from "./components/Mint/Mint";
import Navigation from "./components/_common/Navigation/Navigation";
import { Layout, Modal } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "../src/assets/logo.svg";
import Icon from "../src/assets/icon.svg";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import AlertTemplate from "../src/components/_common/Alert/AlertTemplate";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useActiveWeb3React } from "./components/Wallet/useWalletConnect";
import { specificChainId } from "./utills/constants/contract.constants";

const LayoutComp = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [wrongNetworkmodal, setWrongNetworkmodal] = useState(false);
  const { chainId } = useActiveWeb3React();
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const chainIdcheckerHandler = () => {
    if (chainId === specificChainId) {
      setWrongNetworkmodal(false);
    } else if (chainId === undefined) {
      setWrongNetworkmodal(false);
    } else if (chainId === null) {
      setWrongNetworkmodal(false);
    } else {
      setWrongNetworkmodal(true);
    }
  };

  useEffect(() => {
    chainIdcheckerHandler();
  }, [chainId]);

  const { isLoggedIn } = useSelector((state) => state.auth);
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Layout.Sider
        style={{ minHeight: "100vh" }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="sidebarLogo">
          <div
            style={{
              marginRight: collapsed ? "0px" : "10px",
            }}
          >
            <img
              src={collapsed ? Icon : logo}
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <Navigation style={{ marginTop: "15px" }} />
      </Layout.Sider>
      <Layout className="siteLayout">
        <Header collapsed={collapsed} toggle={toggle} />
        <Layout.Content
          className="siteLayoutBackground"
          style={{
            margin: "24px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Routes>
            <Route path="/" element={<Mint />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout.Content>
      </Layout>
      <Modal
        visible={wrongNetworkmodal}
        onOk={() => {}}
        onCancel={() => {}}
        footer=""
        closable={false}
      >
        <div className="networkModalClass">
          <h1>Invalid Network found</h1>
          <p>Please select desired Network</p>
        </div>
      </Modal>
    </Layout>
  );
};

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "10px",
  transition: transitions.SCALE,
};
const App = () => {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Routes>
          <Route path="/*" element={<LayoutComp />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </AlertProvider>
  );
};

export default App;
