import axios from "axios";
import { store } from "../store";
import { BASE_URL } from "./constants/app.constants";

export const HTTP_CLIENT = axios.create({
  baseURL: BASE_URL,
});

HTTP_CLIENT.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getState().auth;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },

  (err) => {
    return Promise.reject(err);
  }
);

export default HTTP_CLIENT;
