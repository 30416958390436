import React from "react";
import ReactDOM from "react-dom";
import "./custom-antd.less";
import "./global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/index";
import "./font/VistolSans-Black.ttf";
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import { getLibrary } from "./components/Wallet/connectors";
import Web3ReactManager from "./components/Wallet/Web3ReactManager";

const Web3ProviderNetwork = createWeb3ReactRoot("Network");

ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <PersistGate persistor={persistor}>
          <Web3ReactManager>
            <App />
          </Web3ReactManager>
        </PersistGate>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
//
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
