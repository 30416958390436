import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  accessToken: "",
  isAuthenticated: false,
  isVerifying: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.accessToken = action.payload.accessToken;
    },
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setIsVerifying(state, action) {
      state.isVerifying = action.payload;
    },
  },
});

export const { setIsLoggedIn, setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
