import Web3 from "web3";
import MINT_ABI from "../../utills/abi/mint.json";
import { contractAddress } from "../../utills/constants/contract.constants";

export const getProvider = () => {
  const web3 = new Web3();
  web3.setProvider(window.web3.currentProvider);
  return web3;
};
export const mintContact = () => {
  const web3 = getProvider();
  try {
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(MINT_ABI, contractAddress);
      return MyContract;
    }
    return null;
  } catch (err) {
    console.log("error" + err);
  }
};
