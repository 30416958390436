import { BASE_URL } from "../constants/app.constants";

export const URL = {
  LOGIN: BASE_URL + "admin/auth/login",
  VERIFY_EMAIL_CODE: BASE_URL + "admin/auth/verify-login",
  BAN_USER: BASE_URL + "admin/user/ban",
  UNBAN_USER: BASE_URL + "admin/unban-user",
  MULTIPLE_USERS: BASE_URL + "admin/user",
  MULTIPLE_TIERS: BASE_URL + "tier",
  CREATE_NEW_TIER: BASE_URL + "tier",
  DELETE_TIER: BASE_URL + `tier`,
  EDIT_TIER: BASE_URL + `tier`,
  MULTIPLE_GYM_CENTER: BASE_URL + "featured-gym",
  CREATE_NEW_GYM: BASE_URL + "featured-gym/create",
  DELETE_GYM: BASE_URL + `featured-gym`,
  EDIT_GYM: BASE_URL + `featured-gym`,
  FETCH_LEST_OF_GYMS: BASE_URL + `featured-gym/fetch-list`,
  MULTIPLE_SESSIONS: BASE_URL + `session-setting`,
  CREATE_NEW_SESSION: BASE_URL + `session-setting`,
  EDIT_SESSION: BASE_URL + `session-setting`,
  DELETE_SESSION: BASE_URL + `session-setting`,
  MULTIPLE_REFERRALS: BASE_URL + `referral-setting`,
  CREATE_NEW_REFERRAL: BASE_URL + `referral-setting`,
  EDIT_REFERRAL: BASE_URL + `referral-setting`,
  DELETE_REFERRAL: BASE_URL + `referral-setting`,
  SEND_NOTIFICATION_TO_ALL: BASE_URL + "admin/notification/send-to-all",
};
