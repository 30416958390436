import React from "react";
import { Button, Layout } from "antd";
import styles from "./Header.module.scss";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import { injected } from "../../Wallet/connectors";
import { useActiveWeb3React } from "../../Wallet/useWalletConnect";

const Header = ({ collapsed, toggle }) => {
  const { Header } = Layout;
  const { active, account, library, connector, activate, deactivate } =
    useActiveWeb3React();

  async function connect() {
    try {
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  }

  return (
    <>
      <Header className={styles.headerWrapper}>
        {collapsed ? (
          <MenuUnfoldOutlined className={styles.trigger} onClick={toggle} />
        ) : (
          <MenuFoldOutlined className={styles.trigger} onClick={toggle} />
        )}
        {active ? (
          <Button
            className={styles.accountHash}
            type="primary"
            shape="round"
            size="large"
          >
            {account.slice(0, 5) + "..." + account.slice(-5)}
          </Button>
        ) : (
          <Button onClick={connect} type="primary" shape="round" size="large">
            Connect Wallet
          </Button>
        )}
      </Header>
    </>
  );
};

export default Header;
