import { Button, Card, Col, Divider, Input, Row, Tabs } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useState, useEffect } from "react";
import {
  getTotalNFT,
  getSoldNFT,
  getMintFee,
  setMintFee,
  setGoldMember,
  setWhiteMember,
} from "../../services/contractsServices/mint.service";

import styles from "./User.module.scss";
import { useActiveWeb3React } from "../Wallet/useWalletConnect";
import Web3 from "web3";
import { useAlert } from "react-alert";
import { useFormik } from "formik";
import * as yup from "yup";

const Mint = () => {
  const [totalNFT, setTotalNFT] = useState();
  const [soldNFT, setSoldNFT] = useState();
  const [mintFee, setMintFeelocal] = useState();

  const [mintFeeActive, setMintFeeActive] = useState(true);

  const [loadingBtnMint, setLoadingBtnMint] = useState(false);
  const [loadingBtnGold, setLoadingBtnGold] = useState(false);
  const [loadingBtnWhite, setLoadingBtnWhite] = useState(false);

  const { chainId, account, active } = useActiveWeb3React();

  const formikGold = useFormik({
    initialValues: {
      goldListAddress: "",
    },
    validationSchema: yup.object({
      goldListAddress: yup
        .string("Enter your address")
        .required("Address is required")
        .min(42, "Too Short!")
        .max(42, "Too Long!"),
    }),
    onSubmit: (values) => {
      setGoldMemberHandler(values.goldListAddress);
    },
  });
  const formikWhite = useFormik({
    initialValues: {
      whiteListAddress: "",
    },
    validationSchema: yup.object({
      whiteListAddress: yup
        .string("Enter your address")
        .required("Address is required")
        .min(42, "Too Short!")
        .max(42, "Too Long!"),
    }),
    onSubmit: (values) => {
      setWhiteMemberHandler(values.whiteListAddress);
    },
  });

  const alert = useAlert();

  function callback() {
    formikGold.resetForm({
      whiteListAddress: "",
    });
    formikWhite.resetForm({
      whiteListAddress: "",
    });
  }

  const getNFTs = async () => {
    try {
      const totalNFTs = await getTotalNFT();
      setTotalNFT(totalNFTs);
      const soldNFTs = await getSoldNFT();
      setSoldNFT(soldNFTs);
    } catch (error) {
      console.log("err" + error);
    }
  };
  const getMintFee_ = async () => {
    try {
      const _mintFee = await getMintFee();
      const mintFeeEther = Web3.utils.fromWei(_mintFee.toString(), "ether");
      setMintFeelocal(mintFeeEther);
    } catch (error) {
      console.log("err" + error);
    }
  };
  const mintFeeHandler = (e) => {
    setMintFeelocal(e.target.value);
    setMintFeeActive(false);
  };
  const setMintFreeHandler = async () => {
    const mintFeeWie = Web3.utils.toWei(mintFee);
    try {
      setLoadingBtnMint(true);
      await setMintFee(mintFeeWie, account);
      alert.success("Mint fee changed Successfully");
      setLoadingBtnMint(false);
    } catch (error) {
      if (error.message) {
        alert.error(error.message);
        setLoadingBtnMint(false);
      } else {
        alert.error("Mint fee changing failed");
        setLoadingBtnMint(false);
      }
    }
  };

  const setGoldMemberHandler = async (goldListAddress) => {
    try {
      setLoadingBtnGold(true);
      await setGoldMember(goldListAddress, account);
      alert.success("Gold member Initialized");
      setLoadingBtnGold(false);
      formikGold.resetForm({
        goldListAddress: "",
      });
    } catch (error) {
      if (error.message) {
        alert.error(error.message);
        setLoadingBtnGold(false);
      } else {
        alert.error("Initializing gold member failed");
        setLoadingBtnGold(false);
      }
    }
  };
  const setWhiteMemberHandler = async (whiteListAddress) => {
    try {
      setLoadingBtnWhite(true);
      await setWhiteMember(whiteListAddress, account);
      alert.success("Gold member Initialized");
      setLoadingBtnWhite(false);
      formikWhite.resetForm({
        whiteListAddress: "",
      });
    } catch (error) {
      if (error.message) {
        alert.error(error.message);
        setLoadingBtnWhite(false);
      } else {
        alert.error("Initializing gold member failed");
        setLoadingBtnWhite(false);
      }
    }
  };
  useEffect(() => {
    getNFTs();
    getMintFee_();
  }, [chainId]);

  return (
    <>
      <div className={styles.wrapper}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8} md={8} lg={8}>
            <div className={styles.card}>
              <h2 className={styles.count}>{totalNFT ? totalNFT : 0}</h2>
              <h4>Total NFT</h4>
            </div>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8}>
            <div className={styles.card}>
              <h2 className={styles.count}>{soldNFT ? soldNFT : 0}</h2>
              <h4>Sold NFT</h4>
            </div>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8}>
            <div className={styles.card}>
              <h2 className={styles.count}>
                {totalNFT && soldNFT ? totalNFT - soldNFT : 0}
              </h2>
              <h4>Remaining NFT</h4>
            </div>
          </Col>
        </Row>

        <Card title="Mint fee" className={styles.card2}>
          <Input.Search
            disabled={!active && true}
            placeholder="input search text"
            enterButton={
              <Button
                loading={loadingBtnMint}
                disabled={mintFeeActive}
                type="primary"
                size="large"
                style={{ width: "12vw" }}
              >
                Save
              </Button>
            }
            size="large"
            onChange={mintFeeHandler}
            type="number"
            step="0.1"
            // suffix={suffix}
            onSearch={setMintFreeHandler}
            value={mintFee}
          />
        </Card>

        <Tabs
          className={styles.card3}
          type="card"
          defaultActiveKey="1"
          onChange={callback}
        >
          <Tabs.TabPane tab="Gold List" key="1">
            <Card title="Gold List" className={styles.card4}>
              <Input.Search
                id="goldListAddress"
                disabled={!active && true}
                placeholder="enter key here"
                value={formikGold.values.goldListAddress}
                onChange={formikGold.handleChange}
                enterButton={
                  <Button
                    disabled={!active && true}
                    loading={loadingBtnGold}
                    onClick={formikGold.handleSubmit}
                    type="primary"
                    size="large"
                    style={{ width: "12vw" }}
                  >
                    Add
                  </Button>
                }
                size="large"
              />
              <p className="errorText">
                {formikGold.touched.goldListAddress &&
                  formikGold.errors.goldListAddress}
              </p>
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab="White List" key="2">
            <Card title="White List" className={styles.card4}>
              <Input.Search
                id="whiteListAddress"
                disabled={!active && true}
                placeholder="enter key here"
                value={formikWhite.values.whiteListAddress}
                onChange={formikWhite.handleChange}
                enterButton={
                  <Button
                    disabled={!active && true}
                    loading={loadingBtnWhite}
                    onClick={formikWhite.handleSubmit}
                    type="primary"
                    size="large"
                    style={{ width: "12vw" }}
                  >
                    Add
                  </Button>
                }
                size="large"
              />
              <p className="errorText">
                {formikWhite.touched.whiteListAddress &&
                  formikWhite.errors.whiteListAddress}
              </p>
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Mint;
